import gsap from "gsap";
import { Element } from "@/scripts/extends";

export default class Case extends Element {
	mounted() {
		this.imageContainer = this.el.querySelector(".c-case__image-container");

		this.animate();
	}

	animate() {
		this.mm = gsap.matchMedia();

		this.mm.add("(min-width: 768px)", () => {
			gsap.to(this.imageContainer, {
				scrollTrigger: {
					trigger: this.el,
					start: "top 75%",
					end: "top 10%",
					scrub: 1,
					ease: "bounce",
					stagger: 0.1,
				},
				width: "100%",
				translateX: 0,
			})
		})
	}
}
