import { EventEmitter } from "@/scripts/core";
import { EVENTS } from "@/scripts/constants";
import keyboard from "../singletons/Keyboard";

export default class SubNav {
	constructor(el) {
		this.el = el
		this.activeClass = "is-open";
		this.activeBodyClass = "sub-nav-is-open";

		this.isOpen = false;

		this.trigger = this.el.querySelector(".c-sub-nav__trigger");
		this.close = this.el.querySelector(".c-sub-nav__close");
		this.wrapper = this.el.querySelector(".c-sub-nav__wrapper");
		this.links = this.el.querySelectorAll(".c-sub-nav__link");
		this.body = document.body;

		this.events();
		this.initKeyboard();
	}

	events() {
		EventEmitter.on(EVENTS.CURRENT_CLICK_TARGET, (target) => this.onClickBody(target));

		this.trigger.addEventListener("click", () => this.onClickTrigger());
		this.close.addEventListener("click", () => this.disable());
	}

	onClickBody(target) {
		if (target.closest(".c-sub-nav") != this.el) {
			this.disable();
		};
	}

	initKeyboard() {
		keyboard.listenOnKeyDown((e) => this.onKeydown(e));
	}

	onKeydown(e) {
		this.handleEsc(e);
	}

	handleEsc(e) {
		const isEsc = 27;

		if (e.keyCode === isEsc && this.el.matches(':focus-within')) {
			this.trigger.focus();
			this.disable();
		}
	}

	onClickTrigger() {
		this.toggle();
	}

	toggle() {
		if (this.isOpen) {
			this.disable();
		} else {
			this.enable();
		}

		EventEmitter.emit(EVENTS.SUB_NAV_TOGGLE, this.isOpen);
	}

	enable() {
		if (this.el.classList.contains(this.activeClass)) {
			return;
		}

		this.el.classList.add(this.activeClass);
		this.body.classList.add(this.activeBodyClass);
		this.trigger.setAttribute("aria-expanded", true);
		this.wrapper.setAttribute("aria-hidden", false);
		this.enableLinks();

		this.isOpen = true;
	}

	disable() {
		if (!this.el.classList.contains(this.activeClass)) {
			return;
		}

		this.el.classList.remove(this.activeClass);
		this.body.classList.remove(this.activeBodyClass);
		this.trigger.setAttribute("aria-expanded", false);
		this.wrapper.setAttribute("aria-hidden", true);
		this.disableLinks();

		this.isOpen = false;
	}

	enableLinks() {
		this.links.forEach(item => {
			item.setAttribute("tabindex", "0");
		});
	}

	disableLinks() {
		this.links.forEach(item => {
			item.setAttribute("tabindex", "-1");
		});
	}
}
